@import url('https://fonts.googleapis.com/css2?family=Expletus+Sans:wght@400;500;600;700&display=swap');

body {
  font-family: 'Expletus Sans', cursive;
  font-weight: 400;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiMenuItem-root {
  font-size: 14px;
  line-height: 18px;
  font-family: 'Expletus Sans';
  color: #2b2b2b;
}

* {
  box-sizing: border-box;
}
img {
  max-width: 100%;
}
a {
  cursor: pointer;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset !important;
  -webkit-text-fill-color: #2b2b2b !important;
}
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset !important;
  -webkit-text-fill-color: #2b2b2b !important;
}
input[type='number'] {
  appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button,
input[type='number']:hover::-webkit-inner-spin-button, 
input[type='number']:hover::-webkit-outer-spin-button {
-webkit-appearance: none; 
 margin: 0; }
.toast-notification.Toastify__toast-container {
  width: auto;
  max-width: 100%;  
}
.toast-notification.Toastify__toast-container--top-center {
  top: 60px;
  padding: 0;
  margin: 0 24px;
  left: 0;
  right: 0;
  transform: none;
}
.toast-notification .Toastify__toast {
  border-radius: 12px;
  filter: drop-shadow(0px 16px 20px rgba(0, 0, 0, 0.1));
  background-color: #ffffff;
  border: 1px solid #2b2b2b;
  box-shadow: none;
  margin: 0 auto; 
  min-height: 72px;
  padding: 10px 22px;
  width: 100%;
  max-width: 430px;
}
.toast-notification .Toastify__toast.Toastify__toast--error {
  max-width: 680px;
  border: 1px solid #bf3e50;
}
.Toastify__close-button {
  align-self: center;
  color: #bf3e50;
  opacity: 1;
}
.toast-notification .Toastify__toast-body {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  color: #000000;
  font-weight: 400;
  font-family: 'Expletus Sans';
  margin: 0;
  padding: 0;
}
.toast-notification .toast-notification {
  display: flex;
  align-items: center;
}
.toast-notification .notification-txt {
  margin-right: 10px;
}
.toast-notification .profile-notify {
  display:flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.Toastify__toast-body .toast-link {
  font: inherit;
  color: inherit;
  text-decoration: none;
  margin-left: auto;
  text-transform: capitalize;
}
.Toastify__toast-body .toast-link:hover {
  color: #4ba3b7;
  text-decoration: underline;
}
/* .toast-notification .Toastify__progress-bar {
  display: none;
} */

/* autocomplete style start */
.autocomplete-wrapper {
  margin-bottom: 24px;
}
.MuiAutocomplete-root .MuiAutocomplete-inputRoot {
  padding-top: 10px;
  padding-bottom: 10px;
}
.MuiAutocomplete-root .MuiInputBase-root .MuiInputBase-input {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  color: #000000;
  font-weight: 400;
  font-family: 'Roboto';
}
.MuiAutocomplete-root .MuiInputBase-root .MuiInputBase-input.Mui-disabled,
.MuiAutocomplete-root .MuiFormLabel-root.Mui-disabled,
.MuiAutocomplete-root .MuiAutocomplete-endAdornment .MuiIconButton-root.Mui-disabled {
  color: #000000;
  opacity: 0.4;
}
.MuiAutocomplete-root .MuiFormLabel-root {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: #94a3b8;
  font-weight: 500;
  font-family: 'Expletus Sans';
  text-transform: capitalize;
}
.MuiAutocomplete-root .MuiOutlinedInput-root {
  border-radius: 6px;
  min-height: 56px;
}
.MuiAutocomplete-root
  .MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  border-color: #e8ecf2;
}
.MuiAutocomplete-root .MuiOutlinedInput-notchedOutline {
  border-color: #e8ecf2;
}
.MuiAutocomplete-root
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline,
.MuiAutocomplete-root
  .MuiFormLabel-filled
  ~ .MuiOutlinedInput-root
  .MuiOutlinedInput-notchedOutline {
  border-color: rgba(25, 25, 25, 0.32);
  border-width: 1px;
}
.MuiAutocomplete-root .MuiFormLabel-filled,
.MuiAutocomplete-root .MuiInputLabel-outlined.Mui-focused {
  font-size: 12px;
  line-height: 16px;
  color: rgba(43, 43, 43, 0.87);
  letter-spacing: 0.0075em;
  font-weight: 400;
  width: auto;
}
.MuiAutocomplete-root .MuiAutocomplete-endAdornment .MuiIconButton-root {
  color: #959595;
}
.MuiAutocomplete-popper .MuiAutocomplete-paper {
  border-radius: 8px;
  filter: drop-shadow(0px 1px 2.5px rgba(0, 0, 0, 0.2));
  background-color: #fafafa;
  box-shadow: none;
}
.MuiAutocomplete-popper.freeSoloBox .MuiAutocomplete-paper {
  background-color: #fff;
}
.MuiAutocomplete-popper .MuiAutocomplete-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 20px;
  color: #000000;
  font-weight: 500;
  font-family: 'Expletus Sans';
  min-height: 48px;
  padding-right: 16px;
}
.MuiAutocomplete-popper .MuiAutocomplete-option:last-child {
  color: #4ba3b7;
  background: rgba(148, 163, 184, 0.078);
  /* text-transform: capitalize; */
}
.MuiAutocomplete-popper.freeSoloBox .MuiAutocomplete-option:last-child {
  background: transparent;
}
.MuiAutocomplete-popper .MuiAutocomplete-option .MuiLink-root {
  color: inherit;
  text-decoration: none;
}
.MuiAutocomplete-popper .MuiAutocomplete-listbox {
  padding: 8px 0 0;
}
.MuiAutocomplete-popper .menu-wrapper .MuiIconButton-root {
  padding: 0;
}
.MuiButton-root .MuiTouchRipple-root {
  display: none;
}
/* autocomplete style end */

/*Custom Date picker*/
/* .rmdp-wrapper */
.rmdp-wrapper {
  border-radius: 25px;
}
.rmdp-wrapper.top-date-picker{
  position: relative;
  top: 0;
  right: 42px;
  border-radius: 25px;
}

/* .rmdp-wrapper .rmdp-header-values */
.rmdp-wrapper .rmdp-header-values {
  color: #2B2B2B;
  font-family: "Roboto";
  font-size: 15.696px;
  font-weight: 700;
  line-height: 19.184px;
}

/* .rmdp-wrapper .rmdp-header-values span */
.rmdp-wrapper .rmdp-header-values span {
  padding: 0;
}

/* .rmdp-wrapper .rmdp-arrow */
.rmdp-wrapper .rmdp-arrow {
  border-color: #94A3B8;
  height: 6px;
  padding: 4px;
  width: 6px;
}
.rmdp-wrapper .rmdp-arrow:hover {
  border-color: #94A3B8;
  height: 6px;
  padding: 4px;
  width: 6px;
}

/* .rmdp-wrapper .rmdp-arrow-container:hover */
.rmdp-wrapper .rmdp-arrow-container:hover {
  background-color: transparent;
  box-shadow: none;
}
.rmdp-arrow-container:hover .rmdp-arrow {
  border: solid #94A3B8 !important;
  border-width: 0 2px 2px 0;
  cursor: pointer;
}
/* .rmdp-wrapper .rmdp-left i */
.rmdp-wrapper .rmdp-left i {
  margin-left: 6px;
}

/* .rmdp-wrapper .rmdp-right i */
.rmdp-wrapper .rmdp-right i {
  margin-right: 6px;
}

/* .rmdp-wrapper .rmdp-week-day */
.rmdp-wrapper .rmdp-week-day {
  color: #2B2B2B;
  font-size: 16.773px;
  font-weight: 500;
  line-height: normal;
  font-family: "Roboto";
  height: 40px;
  width: 40px;
}

/* .rmdp-wrapper .rmdp-calendar */
.rmdp-wrapper .rmdp-calendar {
  padding: 20px 17px 17px;
}

/* .rmdp-wrapper .rmdp-day-picker */
.rmdp-wrapper .rmdp-day-picker {
  padding: 0;
}

/* .rmdp-wrapper .rmdp-day */
.rmdp-wrapper .rmdp-day {
  color: #2B2B2B;
  height: 40px;
  width: 40px;
}

/* .rmdp-wrapper .rmdp-day.rmdp-selected span:not(.highlight) */
.rmdp-wrapper .rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: #4BA3B7;
  color: #fff;
  box-shadow: none;
}

/* .rmdp-wrapper .rmdp-day.rmdp-disabled */
.rmdp-wrapper .rmdp-day.rmdp-disabled {
  color: grey;
}

/* .rmdp-wrapper .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover */
.rmdp-wrapper .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  background-color: #4BA3B7;
  color: #fff;
}

/* .rmdp-wrapper .rmdp-day span */
.rmdp-wrapper .rmdp-day span {
  font-size: 16.773px;
  font-weight: 500;
  line-height: normal;
  font-family: "Roboto";
  padding: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* .rmdp-wrapper .rmdp-day.rmdp-today span */
.rmdp-wrapper .rmdp-day.rmdp-today span {
  background-color: transparent;
  color: #7fdbff;
}

/* .rmdp-wrapper .rmdp-range, .rmdp-wrapper .rmdp-range-hover */
.rmdp-wrapper .rmdp-range,
.rmdp-wrapper .rmdp-range-hover {
  background-color: #ECF1F4;
  box-shadow: none;
}

/* .rmdp-wrapper .rmdp-range.rmdp-today.start span, .rmdp-wrapper .rmdp-range.rmdp-today.end span */
.rmdp-wrapper .rmdp-range.rmdp-today.start span,
.rmdp-wrapper .rmdp-range.rmdp-today.end span {
  background-color: #4BA3B7;
  color: #fff;
}

/* .rmdp-wrapper .rmdp-range.start, .rmdp-wrapper .rmdp-range.end */
.rmdp-wrapper .rmdp-range.start,
.rmdp-wrapper .rmdp-range.end {
  color: #fff;
  background: #4BA3B7;
}

/* .rmdp-wrapper .rmdp-button-wrapper */
.rmdp-wrapper .rmdp-button-wrapper {
  border-radius: 0 0 24px 24px;
  background: rgba(43, 43, 43, 0.87);
}

/* .rmdp-wrapper .rmdp-button-wrapper .button-actions */
.rmdp-wrapper .rmdp-button-wrapper .button-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 19px 19px;
  border-radius: 0 0 24px 24px;
  background: #ffffff;
}

/* .rmdp-wrapper .rmdp-button-wrapper .button-actions .secondary-btn */
.rmdp-wrapper .rmdp-button-wrapper .button-actions .secondary-btn {
  min-width: unset;
  border: 0;
  padding: 6px 12px;
  min-height: 30px;
  color: #4BA3B7;
  margin-right: 12px;
  width: auto;
}

/* .rmdp-wrapper .rmdp-button-wrapper .button-actions .primary-btn */
.rmdp-wrapper .rmdp-button-wrapper .button-actions .primary-btn {
  min-width: unset;
  border: 0;
  padding: 6px 12px;
  min-height: 30px;
  width: auto;
}

/* .rmdp-wrapper .rmdp-button-wrapper .button-bottom-actions */
.rmdp-wrapper .rmdp-button-wrapper .button-bottom-actions {
  padding: 24px 15px 0 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

/* .rmdp-wrapper .rmdp-button-wrapper .button-bottom-actions .tasklist-link */
.rmdp-wrapper .rmdp-button-wrapper .button-bottom-actions .tasklist-link {
  color: #FFF;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.105px;
  text-decoration: none;
  margin-right: 16px;
}

/* .rmdp-wrapper .rmdp-button-wrapper .button-bottom-actions .tasklist-link:last-child */
.rmdp-wrapper .rmdp-button-wrapper .button-bottom-actions .tasklist-link:last-child {
  margin-right: 0;
}

@media (max-width: 599px) {
  .toast-notification .Toastify__toast-body { 
    letter-spacing: 0.15px;
    color: rgba(43, 43, 43, 0.87);
  }
  .toast-notification .Toastify__toast { 
    filter: none;    
    box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.1);
  }
  .toast-notification .Toastify__toast.Toastify__toast--error {      
    border: 1px solid #C7263E;   
  }
  .toast-notification.Toastify__toast-container--top-center {
    top: 9px;  
  }
  .Toastify__close-button {
    color: #C7263E;
  }
}